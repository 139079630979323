// Config (Add more domains here if you need)
export const SITES = {
  'club1909': {
    hosts: [
      'https://club1909.com',
      'https://staging.club1909.com',
      'https://ch-club-1909.local',
    ],
    // In the future, we can add new properties here for
    // more config options (theme, global vars, etc.) if need be
  },
  '7thplayernhl': {
    hosts: [
      'https://7ejoueur.canadiens.nhl.com',
      'https://dev.7ejoueur.canadiens.nhl.com',
    ],
  },
  'fanclub': {
    hosts: [
      'https://fanclub.canadiens.com',
      'https://dev.fanclub.canadiens.com',
      'https://ch-fanclub.local',
      'https://fanclub.local'
    ],
  },
};

export const DOMAIN =  {
  'club1909': {
    local: 'https://ch-club-1909.local',
    dev: 'https://staging.club1909.com',
    prod: 'https://club1909.com',
  },
  '7thplayernhl': {
    local: 'https://ch-7thplayer.local',
    dev: 'https://dev.7ejoueur.canadiens.nhl.com',
    prod: 'https://7ejoueur.canadiens.nhl.com',
  },
  'fanclub': {
    local: 'https://fanclub.local',
    dev: 'https://dev.fanclub.canadiens.com',
    prod: 'https://fanclub.canadiens.com',
  },
}
