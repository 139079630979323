<template>
  <!-- Login -->
  <div class="prompt">
    <!-- Title -->
    <h2>{{ $t('login.title') }}</h2>
    <!-- Description -->
    <p>{{ $t('prompt.must_be_member') }}</p>
    <!-- Button -->
    <a
      class="btn"
      @click.prevent="handleBtnClick"
    >
      <span class="btn__label">
        {{ $t('prompt.action') }}
      </span>
    </a>
    <!-- Separator -->
    <div class="prompt__separator">
      <hr>
      <h3>{{ $t('prompt.or') }}</h3>
    </div>
  </div>
</template>

<script>
import settings from '@/mixins/settings';
import redirect from '@/mixins/redirect';

export default {
  name: 'Prompt',
  mixins: [settings, redirect],
  methods: {
    // Redirect to login. Bust redirect to iFrame's parent
    handleBtnClick () {
      const { origin, search } = window.location;

      this.redirect(origin + search, 'prompt');
    },
  },
};
</script>

<style lang="scss" scoped>
// Separator
.prompt__separator {
  position: relative;

  h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;

    @include rem(width, 60px);
    @include rem(height, 60px);

    margin: 0;
    background-color: white;
    border: 2px solid $color-border-light;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  hr {
    margin: $spacer*4 0;
  }
}
</style>
