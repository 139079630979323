<template>
  <!-- Form -->
  <form
    :class="[
      'form',
      { 'form--light': light },
    ]"
  >
    <!-- Form group -->
    <div
      v-for="(field, index) in data"
      :key="index"
      class="form-group"
    >
      <!-- Separator -->
      <hr v-if="field.type === 'separator'">
      <!-- Title -->
      <h4 v-if="field.type === 'title'">
        {{ $t(field.label) }}
      </h4>
      <!-- Label -->
      <label
        v-if="!['separator', 'title', 'checkbox', 'select_region'].includes(field.type)
          && (!field.isManualEntry
            || (field.isManualEntry && manualEntry))"
        :for="field.model"
        class="form-label"
        v-html="formatInputLabel(field)"
      />
      <!-- Label select region -->
      <label
        v-if="['select_region'].includes(field.type)
          && inputFields[field.sibling_model]
          && (inputFields[field.sibling_model] === 'CA'
            || inputFields[field.sibling_model] === 'US')"
        :for="field.model"
        class="form-label"
        v-html="formatInputLabel(field)"
      />
      <!-- Input text -->
      <input
        v-if="['text', 'email'].includes(field.type)
          && (!field.isManualEntry || field.isManualEntry && manualEntry)"
        :id="field.model"
        v-model="inputFields[field.model]"
        v-validate="field.validations"
        :type="field.type"
        :data-vv-name="field.model"
        :name="field.model"
        :data-vv-scope="field.model"
        :placeholder="field.placeholder"
        :class="[
          'form-input',
          { 'is-invalid': fieldIsInvalid(field.model) || (field.type === 'email' && emailAlreadyUsed) }
        ]"
        :maxlength="field.maxLength"
        v-on="field.type === 'email' ? { blur: checkEmail } : {}"
      >
      <!-- Input password -->
      <input
        v-if="['password'].includes(field.name)"
        :id="field.model"
        v-model="inputFields[field.model]"
        v-validate="field.validations"
        :type="field.type"
        :data-vv-name="field.model"
        :name="field.model"
        :data-vv-scope="field.model"
        :class="{
          'form-input': true,
          'is-invalid': fieldIsInvalid(field.model) || (passwordFeedbackVisible && !passwordFormatValid),
          'is-success': passwordFeedbackVisible && passwordFormatValid,
        }"
        :maxlength="field.maxLength"
        @input="handlePasswordInput"
      >
      <!-- Input password feedback -->
      <div
        v-if="field.model === 'password' && passwordFeedbackVisible"
        :class="[
          'form-password-feedback',
          { 'is-success': passwordFormatValid },
        ]"
      >
        <div
          v-for="(condition, conditionIndex) in Object.entries(field.requirements)"
          :key="`pw-req-${conditionIndex}-${condition[conditionIndex]}`"
        >
          <template v-if="condition[1] === false">
            <i class="material-icons is-danger">close</i>
            <span class="is-danger">
              {{ formatFeedback(condition) }}
            </span>
          </template>
          <template v-else>
            <i class="material-icons is-success">check</i>
            <span class="is-success">
              {{ formatFeedback(condition) }}
            </span>
          </template>
        </div>
      </div>
      <!-- Input password confirmation -->
      <input
        v-if="['password_confirmation'].includes(field.name)"
        :id="field.model"
        v-model="inputFields[field.model]"
        v-validate="field.validations"
        :type="field.type"
        :data-vv-name="field.model"
        :name="field.model"
        :data-vv-scope="field.model"
        :class="{
          'form-input': true,
          'is-invalid': fieldIsInvalid(field.model),
        }"
        :maxlength="field.maxLength"
        @input="checkPasswordMatch"
      >
      <!-- Input date dropdown -->
      <date-dropdown
        v-if="['date'].includes(field.type)"
        :id="field.model"
        v-model="inputFields[field.model]"
        :i18n="$t('form.months')"
        :max-year="maxYear"
      />
      <!-- Label date disclaimer -->
      <span
        v-if="['date'].includes(field.type)"
        class="form-date-disclaimer"
        v-html="$t('form.date_disclaimer')"
      />
      <!-- Input select -->
      <select
        v-if="['select'].includes(field.type)"
        :id="field.model"
        v-model="inputFields[field.model]"
        v-validate="field.validations"
        :data-vv-name="field.model"
        placeholder="test"
        :class="{
          'form-input': true,
          'form-select': true,
          'is-invalid': fieldIsInvalid(field.model)
        }"
      >
        <option
          value=""
          selected
          disabled
          v-html="$t(`form.select_${field.name}`)"
        />
        <option
          v-for="(option, i) in field.options"
          :key="i"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
      <!-- Input Google autocomplete -->
      <template v-if="['autocomplete'].includes(field.type)">
        <vue-google-autocomplete
          :id="field.model"
          v-model="inputFields.autocompleteAddress"
          v-validate="{ required: !manualEntry }"
          :class="{
            'is-invalid': fieldIsInvalid(field.model)
          }"
          :data-vv-name="field.model"
          :placeholder="$t('form.start_typing')"
          :enable-geolocation="true"
          class="form-input"
          type="search"
          @placechanged="fillAddressData"
        />
        <a
          v-if="!manualEntry"
          href="#"
          class="form-manual-entry"
          @click.prevent="handleToggleAddress"
        >
          {{ $t('form.enter_address') }}
        </a>
      </template>
      <!-- Input checkbox -->
      <Checkbox
        v-if="['checkbox'].includes(field.type)"
        :id="field.model"
        v-model="inputFields[field.model]"
        v-validate="field.validations"
        :data-vv-name="field.model"
        :label="field.label"
        :value="inputFields[field.model]"
      />
      <!-- Country / Region selection -->
      <country-select
        v-if="['select_country'].includes(field.type)
          && (field.isManualEntry && manualEntry)"
        :id="field.model"
        v-model="inputFields[field.model]"
        v-validate="field.validations"
        :country="inputFields[field.model]"
        :data-vv-name="field.model"
        :name="field.model"
        :data-vv-scope="field.model"
        :class="{
          'is-invalid': fieldIsInvalid(field.model)
        }"
        :placeholder="$t('form.select_country')"
        :usei18n="false"
        top-country="CA"
        class-name="form-input form-select"
      />
      <region-select
        v-if="['select_region'].includes(field.type)
          && (inputFields[field.sibling_model]
            && inputFields[field.sibling_model] === 'CA'
            || inputFields[field.sibling_model] === 'US')
          && (field.isManualEntry && manualEntry)"
        :id="field.model"
        v-model="inputFields[field.model]"
        v-validate="field.validations"
        :region="inputFields[field.model]"
        :country="inputFields[field.sibling_model]"
        :data-vv-name="field.model"
        :name="field.model"
        :data-vv-scope="field.model"
        :class="{
          'is-invalid': fieldIsInvalid(field.model)
        }"
        :placeholder="$t('form.select_region')"
        :usei18n="false"
        class-name="form-input form-select"
      />
      <!-- Input validation alert -->
      <Alert
        v-if="fieldIsInvalid(field.model)"
        :message="formatValidationMessage(field)"
        :type="['autocomplete'].includes(field.type) ? 'overflow' : null"
        :light="isCreate"
      />
    </div>
    <!-- Terms & Conditions -->
    <div
      v-if="isCreate"
      class="form-group"
    >
      <Checkbox
        v-if="isCreate"
        id="terms"
        v-model="termsChecked"
      >
        <p
          v-if="isCreate"
          class="form-terms"
        >
          {{ $t('form.terms_desc1') }}
          <a
            :href="$t('form.terms_url')"
            target="_blank"
          >
            {{ $t('form.terms_desc2') }}
          </a>
          <template v-if="isSiteGamification">
            {{ $t('form.terms_desc3') }}
            <a
              :href="$t('chgamification.rules')"
              target="_blank"
            >
              {{ $t('form.terms_desc4') }}
            </a>
          </template>
        </p>
      </Checkbox>
    </div>

    <div
      v-if="isCreate"
      class="form-group"
    >
      <p
        v-if="isCreate"
        class="optin"
      >
        {{ $t('form.optin_desc1') }}
        <a
          :href="$t('form.optin_privacy_policies_url')"
          target="_blank"
        >
          {{ $t('form.optin_desc2') }}
        </a>
        {{ $t('form.optin_desc3') }}
        <a
          :href="$t('form.optin_terms_conditions_url')"
          target="_blank"
        >
          {{ $t('form.optin_desc4') }}
        </a>
      </p>
    </div>
    <!-- Submit -->
    <div
      :class="[
        'form-submit',
        { 'form-submit--multiple-links': links && links.length > 1 },
      ]"
    >
      <!-- Invisible Recaptcha -->
      <invisible-recaptcha
        :sitekey="recaptchaSiteKey"
        :callback="handleSubmit"
        :class="{
          'is-loading': isLoading,
        }"
        class="btn"
        type="submit"
      >
        <template v-if="isLoading">
          <span class="loading-spin-container">
            <i class="material-icons loading-spin">replay</i>
          </span>
        </template>
        <span class="btn__label">{{ btnLabel }}</span>
      </invisible-recaptcha>
      <!-- Links -->
      <template v-if="links">
        <router-link
          v-for="link in links"
          :key="link.label"
          :to="link.to"
          class="link-alt"
        >
          {{ link.label }}
        </router-link>
      </template>
    </div>
    <!-- General alert -->
    <Alert
      v-if="formMessage"
      :message="formMessage"
      :light="isCreate"
      type="single"
    />
  </form>
</template>

<script>
import { mapState } from 'vuex';
import Cookies from 'js-cookie';
import { get } from 'lodash';

import settings from '@/mixins/settings';
import redirect from '@/mixins/redirect';

import VueGoogleAutocomplete from 'vue-google-autocomplete';
import InvisibleRecaptcha from 'vue-invisible-recaptcha';

import Alert from '@/components/Alert.vue';
import Checkbox from '@/components/Checkbox.vue';
import DateDropdown from '@/components/DateDropdown.vue';

export default {
  name: 'Form',
  components: {
    VueGoogleAutocomplete,
    InvisibleRecaptcha,
    Alert,
    Checkbox,
    DateDropdown,
  },
  mixins: [settings, redirect],
  props: {
    data: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    links: {
      type: [Object, Array],
      required: false,
      default: null,
    },
    light: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      manualEntry: false,
      passwordFeedbackVisible: false,
      passwordFormatValid: false,
      bothPasswordMatch: null,
      emailAlreadyUsed: null,
      formMessage: null,
      termsChecked: false,
      isLoading: false,
      cachedEmail: '',
      inputFields: {
        firstName: this.$route.query.firstName || '',
        lastName: this.$route.query.lastName || '',
        gender: '',
        birthDate: '',
        email: this.$route.query.email || '',
        password: '',
        passwordConfirmation: '',
        phone: '',
        cellphone: '',
        autocompleteAddress: '',
        address: '',
        city: '',
        country: '',
        region: '',
        postalCode: '',
        chgmOptin: false,
        rememberMe: false,
      },
    };
  },
  computed: {
    ...mapState('formOptions', ['languages']),
    // Label of the submit button
    btnLabel () {
      return this.$t(`form.${this.isCreate ? 'become_member' : 'submit'}`);
    },
    // Check if the form should apply "create" conditions
    isCreate () {
      return this.type === 'create';
    },
    // Check if the form should apply "login" conditions
    isLogin () {
      return this.type === 'login';
    },
    // Check if the form should apply "forgot" conditions
    isForgot () {
      return this.type === 'forgot';
    },
    // Check if the form should apply "reset" conditions
    isReset () {
      return this.type === 'reset';
    },
    // Create a computed property for mutations
    countryInputField () {
      return this.inputFields.country;
    },
    // Use recaptcha site key
    recaptchaSiteKey () {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY
        ? process.env.VUE_APP_RECAPTCHA_SITE_KEY
        : null;
    },
    // Formatted max year used in date dropdown (13 years old and older)
    maxYear () {
      return new Date().getFullYear() - 13;
    },
    // Check if the password fill all requirements
    passwordRequirements () {
      const entry = this.data.find(one => one.name === 'password');
      return entry.requirements;
    },
  },
  watch: {
    // Clear region model if the country chosen does not have any region
    countryInputField (country) {
      if (!(country === 'CA' || country === 'US')) {
        this.inputFields.region = '';
      }
    },
  },
  methods: {
    // Format the password feedback message
    formatFeedback (condition) {
      let message;
      let short = condition[0] === 'password_has_accepted_char' ? '_short' : '';

      if (condition[0] === 'password_has_length') {
        short = '_shorter';
      }

      if (condition[1] === false) {
        message = this.$t(`validation.${condition[0]}`,
          { feedback: this.$t(`validation.negative_feedback${short}`) });
      } else {
        message = this.$t(`validation.${condition[0]}`,
          { feedback: this.$t(`validation.positive_feedback${short}`) });
      }

      return message;
    },
    // Return the right label message in the form loop
    formatInputLabel (field) {
      const required = field.validations.required || field.type === 'autocomplete';

      return `${this.$t(`form.${field.name}`)}${required ? '*' : ''}`;
    },
    // Check if the form has invalid Front-End fields
    fieldIsInvalid (field) {
      let condition = this.errors.items.find(item => item.field === field);

      if (field === 'passwordConfirmation') {
        condition = condition
          || this.bothPasswordMatch === false;
      } else if (field === 'email') {
        condition = condition
          || this.emailAlreadyUsed;
      }

      return condition;
    },
    // Call API to check if email exists
    async checkEmail () {
      this.emailAlreadyUsed = false;
      const email = this.inputFields.email;

      if (this.isCreate && email !== '' && email !== this.cachedEmail) {
        this.$axios.get('/api/verify/email', { params: { email } })
          // If success, validate email
          .then(() => {
            this.emailAlreadyUsed = false;
          })
          // If error 409, give the email field an error
          .catch((error) => {
            const code = get(error, 'response.data.status.code', null)
            if (code === 409) {
              this.emailAlreadyUsed = true;
            }
          })
          // Always cache the email to prevent too many API calls
          // (will call the API only if inputed email is different)
          .then(() => {
            this.cachedEmail = email;
          });
      }
    },
    // On input, check if certain conditions are met for the password to be valid
    handlePasswordInput (event) {
      if (['password'].includes(event.target.name)) {
        const { value } = event.target;
        this.checkPasswordMatch();

        if (this.passwordRequirements) {
          this.passwordFeedbackVisible = true;
          this.passwordRequirements.password_has_accepted_char = /^[^\s]+$/.test(value)
            && value.length >= 1;
          this.passwordRequirements.password_has_length = value.length >= 8 && value.length <= 20;
          this.passwordRequirements.password_has_capital = value.toLowerCase() !== value;
          this.passwordRequirements.password_has_lowercase = value.toUpperCase() !== value;
          this.passwordRequirements.password_has_number = /\d/.test(value);
          this.passwordFormatValid = Object.values(this.passwordRequirements).every(item => item);
        }
      }
    },
    // Return true or false if both password fields match or no
    checkPasswordMatch () {
      const { password, passwordConfirmation } = this.inputFields;
      this.bothPasswordMatch = password === passwordConfirmation;
    },
    // Show / hide the address on "Manual entry" link click
    handleToggleAddress () {
      this.manualEntry = !this.manualEntry;
    },
    // Return the right message according to the email validation (already used or general mandatory)
    formatValidationMessage (field) {
      if (field.name === 'email' && this.emailAlreadyUsed) {
        return this.$t('validation.email_already_used');
      }
      return this.$t(`validation.${field.validationMessage}`);
    },
    // When the location found
    // @param {Object} addressData Data of the found location
    // @param {Object} placeResultData PlaceResult object
    // @param {String} id Input container ID
    fillAddressData (addressData, placeResultData) {
      this.manualEntry = true;
      const { country } = addressData;
      const region = addressData.administrative_area_level_1;
      const ISOCountry = this.getISOcode(country, placeResultData);
      const ISORegion = this.getISOcode(region, placeResultData);
      this.inputFields.country = ISOCountry;
      this.inputFields.region = ISORegion;
      this.inputFields.city = addressData.locality;
      this.inputFields.postalCode = addressData.postal_code;
      this.inputFields.address = placeResultData.name;
    },
    // Return the right address ISO code if available to be use in the Google Autocomplete
    getISOcode (item, resultData) {
      const data = resultData.address_components.find((one) => {
        if (one.long_name === item) {
          return one.short_name;
        }
      });
      return data && data.short_name ? data.short_name : item;
    },
    // Handle form validation + API call + Parent redirects
    async handleSubmit (recaptchaToken) {
      this.isLoading = true;
      this.formMessage = null;
      let termsCondition = true;
      const veeValidation = await this.$validator.validate();

      await this.checkEmail();

      if (this.isCreate) {
        termsCondition = this.termsChecked;
      }

      this.checkPasswordMatch();

      // Custom validation on create and reset forms
      let customValidation = true;

      if (this.isCreate) {
        // Check password + email
        customValidation = this.bothPasswordMatch
          && this.passwordFormatValid
          && !this.emailAlreadyUsed;
      } else if (this.isReset) {
        // Check password
        customValidation = this.bothPasswordMatch
          && this.passwordFormatValid;
      }

      // Check if the form is valid and the recaptcha returned a string
      if (veeValidation
        && customValidation
        && recaptchaToken
        && termsCondition) {
        let endpoint;
        let post;

        if (this.isLogin) {
          endpoint = '/api/login',
          post = {
            email: this.inputFields.email,
            password: this.inputFields.password,
          };
        } else if (this.isCreate) {
          endpoint = '/api/account/create',
          post = {
            firstname: this.inputFields.firstName,
            lastname: this.inputFields.lastName,
            email: this.inputFields.email,
            password: this.inputFields.password,
            password_confirmation: this.inputFields.passwordConfirmation,
            phone: this.inputFields.phone,
            cellphone: this.inputFields.cellphone,
            gender: this.inputFields.gender || 'U',
            birthdate: this.inputFields.birthDate,
            address: this.inputFields.address,
            country: this.inputFields.country,
            state: this.inputFields.region,
            city: this.inputFields.city,
            postal_code: this.inputFields.postalCode,
            lang: this.lang,
            origin: this.site,
            optin: 'Y',
          };
          if (this.isSiteGamification) {
            Object.assign(post, { chgmOptin: this.inputFields.chgmOptin });
          }
        // Todo: Refactor (remove & move in Axios) this when API is available
        } else if (this.isForgot) {
          endpoint = '/api/password/forgot',
          post = {
            email: this.inputFields.email,
            locale: this.lang,
          };
        } else if (this.isReset) {
          const { token } = this.$route.query;
          endpoint = '/api/password/reset',
          post = {
            token,
            password: this.inputFields.password,
            password_confirmation: this.inputFields.passwordConfirmation,
          };
        }

        // API call
        await this.axios.post(endpoint, post)
          .then((response) => {
            if (this.isLogin || this.isCreate) {
              const { token } = response.data.data;
              const tokenQuery = token ? `?token=${token}` : '';
              let originAppQuery = '';
              let chgmOptinQuery = '';

              if (this.isSiteClub1909 && this.$route.query.origin === 'app') {
                originAppQuery = token ? '&origin=app' : '?origin=app';
              }

              if (this.isCreate && post.chgmOptin) {
                chgmOptinQuery = `&chgm_optin=${post.chgmOptin}`;
              }

              const parsedUri = `${this.redirectUri}${tokenQuery}${originAppQuery}${chgmOptinQuery}`;

              if ((this.isLogin && this.inputFields.rememberMe) || this.isCreate) {
                Cookies.set('ch-sso-token', token, { expires: 30, domain: '.canadiens.com' })
              }

              // Send event to analytics
              this.$gtag.event(this.type, {
                'event_category': 'engagement',
                'event_label': post.email
              });

              this.redirect(parsedUri, this.type);
            } else if (this.isForgot || this.isReset) {
              this.$emit('submitted', get(post, 'email', null));
            }
          })
            // Handle API errors
          .catch((error) => {
            const { response } = error;
            let errorLabel = 'error.something_went_wrong';

            if (response !== undefined) {
              const { msg } = response.data.data;
              errorLabel = msg;
            }

            this.isLoading = false;
            this.formMessage = this.$t(errorLabel);
          });
      } else {
        // Handle Front-End validation errors
        this.isLoading = false;
        this.formMessage = this.$t('error.form');

        if (this.isCreate && !this.termsChecked) {
          this.formMessage = this.$t('error.terms');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// General
.form {
  margin: auto;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-label {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;

  @include rem(margin-bottom, 10px);

  &.is-disabled {
    pointer-events: none;
  }
}

.form-select,
/deep/ .date-dropdown__select {
  padding-right: $spacer * 2;
  background: white url('../assets/img/chevron.png') no-repeat;

  @include rem(background-size, 13px);

  background-position: right $spacer/2 center;
  font-weight: $font-weight-base;
  color: black;
  cursor: pointer;
}

.form-input,
/deep/ .date-dropdown__select {
  appearance: none;
  width: 100%;

  @include rem(min-height, 45px);

  padding: 0 $spacer/2;
  margin-bottom: $spacer;
  border: 0;
  border-radius: $border-radius-base;
  font-size: $font-size-sm;
  font-weight: $font-weight-base;

  .form--light & {
    border: 1px solid $color-border-base;
  }

  &.is-invalid {
    border: 2px solid $color-danger;
    border-bottom: 0;

    &::placeholder {
      color: $color-danger;
    }

    .form--light & {
      border-bottom: 1px solid $color-border-base;
    }
  }

  &.is-success {
    border: 2px solid $color-success;
    border-bottom: 0;

    .form--light & {
      border-bottom: 1px solid $color-border-base;
    }
  }

  &.is-disabled {
    background-color: darken($color-gray-light, 5%);
    pointer-events: none;
  }

  @media (min-width: $bp-xs) {
    padding: 0 $spacer/2;
  }
}

.form-date-disclaimer {
  margin: -$spacer 0 $spacer;
  font-size: $font-size-sm;
}

// Password feedback
.form-password-feedback {
  padding: $spacer;

  @include rem(margin, -18px 0 16px);

  border: 2px solid $color-danger;
  border-top: 0;

  div {
    display: flex;
    font-size: $font-size-sm;
  }

  i {
    margin: $spacer/10 $spacer/3 0 0;

    @include rem(font-size, 16px);
  }

  span,
  i {
    &.is-danger {
      color: $color-danger;
    }

    &.is-success {
      color: $color-success;
    }
  }

  &.is-success {
    border-color: $color-success;
  }

  .form:not(.form--light) & span {
    color: white;
  }
}

// Manual entry
.form-manual-entry {
  position: relative;
  align-self: flex-end;
  margin: -$spacer 0 $spacer;
  font-size: $font-size-sm;
  z-index: 1;
}

// Submit
.form-submit {
  margin-top: $spacer;

  > .btn {
    width: 100%;
    padding: 0;

    @include rem(max-width, 230px);

    @media (min-width: $bp-sm) {
      margin-right: $spacer*2;
    }
  }
}

// Terms
.form-terms {
  font-size: $font-size-sm;
  margin-bottom: $spacer;

  @media (min-width: $bp-sm) {
    margin-bottom: 0;
  }
}

// Link Alt
.link-alt {
  margin-top: $spacer;

  @media (min-width: $bp-sm) {
    margin-top: 0;
  }
}

// Separator
hr {
  margin: $spacer*2 0 $spacer*3;
  transition: $transition-base;
}

// Modifiers
.form-submit--multiple-links {
  justify-content: space-between;

  > a {
    margin-left: 0;
  }
}
</style>
