<template>
  <!-- Create -->
  <Full
    v-if="!isInMaintenance"
    :title="$t('create.title')"
    :description="$t('form.disclaimer_mandatory')"
  >
    <!-- Prompt -->
    <Prompt
      v-if="showPrompt"
      slot="top"
    />
    <div slot="bottom">
      <!-- Form -->
      <Form
        :data="formData"
        type="create"
        light
      />
      <!-- Recaptcha disclaimer -->
      <p
        class="grecaptcha-disclaimer"
        v-html="$t('form.grecaptcha_disclaimer')"
      />
    </div>
  </Full>

  <!-- Maintenance -->
  <Maintenance
    v-else
    :full="true"
  />
</template>

<script>
import settings from '@/mixins/settings';

import Full from '@/components/Container/Full.vue';
import Prompt from '@/components/Prompt.vue';
import Form from '@/components/Form.vue';
import Maintenance from "@/components/Maintenance.vue";

export default {
  name: 'Create',
  components: {
    Maintenance,
    Full,
    Form,
    Prompt,
  },
  mixins: [settings],
  data () {
    return {
      formData: [
        {
          type: 'title',
          label: 'separator.general_information',
        },
        {
          type: 'email',
          name: 'email',
          model: 'email',
          placeholder: this.$t('form.email_example'),
          validations: {
            required: true,
            regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
          },
          validationMessage: 'email',
          maxLength: 250,
        },
        {
          type: 'password',
          name: 'password',
          model: 'password',
          validations: {
            required: true,
          },
          requirements: {
            password_has_accepted_char: false,
            password_has_length: false,
            password_has_number: false,
            password_has_capital: false,
            password_has_lowercase: false,
          },
          validationMessage: 'mandatory',
          maxLength: 20,
        },
        {
          type: 'password',
          name: 'password_confirmation',
          model: 'passwordConfirmation',
          validations: {
            required: true,
          },
          validationMessage: 'password_confirmation',
          maxLength: 20,
        },
        {
          type: 'separator',
        },
        {
          type: 'title',
          label: 'separator.personal_information',
        },
        {
          type: 'text',
          name: 'first_name',
          model: 'firstName',
          validations: {
            required: true,
          },
          validationMessage: 'mandatory',
          maxLength: 40,
        },
        {
          type: 'text',
          name: 'last_name',
          model: 'lastName',
          validations: {
            required: true,
          },
          validationMessage: 'mandatory',
          maxLength: 80,
        },
        {
          type: 'text',
          name: 'phone',
          model: 'phone',
          validations: {
            required: true,
            regex: /^(?=.*?[1-9]{1})[0-9 ()+-]+$/,
          },
          validationMessage: 'mandatory_phone',
          maxLength: 20,
        },
        {
          type: 'text',
          name: 'cellphone',
          model: 'cellphone',
          validations: {
            regex: /^(?=.*?[1-9]{1})[0-9 ()+-]+$/,
          },
          validationMessage: 'cellphone',
          maxLength: 20,
        },
        {
          type: 'select',
          name: 'gender',
          model: 'gender',
          validations: {
            required: false,
          },
          options: [
            { label: this.$t('form.man'), value: 'M' },
            { label: this.$t('form.woman'), value: 'F' },
            { label: this.$t('form.gender_neutral'), value: 'U' },
          ],
        },
        {
          type: 'date',
          name: 'birth_date',
          model: 'birthDate',
          validations: {
            required: true,
          },
          validationMessage: 'mandatory',
        },
        {
          type: 'separator',
        },
        {
          type: 'title',
          label: 'separator.mailing_address',
        },
        {
          type: 'autocomplete',
          name: 'find_my_address',
          model: 'autocompleteAddress',
          validations: {
            required: false,
          },
          validationMessage: 'mandatory',
        },
        {
          type: 'text',
          name: 'address',
          model: 'address',
          validations: {
            required: true,
          },
          isManualEntry: true,
          validationMessage: 'mandatory',
          maxLength: 40,
        },
        {
          type: 'select_country',
          name: 'country',
          model: 'country',
          validations: {
            required: true,
          },
          isManualEntry: true,
          validationMessage: 'mandatory',
        },
        {
          type: 'select_region',
          name: 'region',
          model: 'region',
          sibling_model: 'country',
          validations: {
            required: true,
          },
          isManualEntry: true,
          validationMessage: 'mandatory',
        },
        {
          type: 'text',
          name: 'city',
          model: 'city',
          validations: {
            required: true,
          },
          isManualEntry: true,
          validationMessage: 'mandatory',
          maxLength: 40,
        },
        {
          type: 'text',
          name: 'postal_code',
          model: 'postalCode',
          validations: {
            required: true,
          },
          isManualEntry: true,
          validationMessage: 'mandatory',
          maxLength: 10,
        },
      ],
    };
  },
  created () {
    // Add optional opt-in checkbox if certain conditions are met
    if (this.site && this.isSiteGamification) {
      this.formData.push({
        type: 'checkbox',
        name: 'chgm_optin',
        model: 'chgmOptin',
        validations: {
          required: false,
        },
        label: this.$t('form.notifications'),
      });
    }
  },
};
</script>

<style lang="scss" scoped>
// General
.grecaptcha-disclaimer {
  margin-top: $spacer*2;
  font-size: $font-size-sm;
}
</style>
