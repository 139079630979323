import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import VeeValidate from 'vee-validate';
import vueCountryRegionSelect from 'vue-country-region-select';

import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import VueGtag from 'vue-gtag';

// Set api root baseURL
axios.defaults.baseURL = process.env.VUE_APP_API_ROOT;

// Init Sentry error tracking
const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  });
}

Vue.prototype.$axios = axios;
Vue.use(VeeValidate);

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);

Vue.use(vueCountryRegionSelect);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_TAG },
});

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
