<template>
  <!-- Forgot -->
  <Box
    v-if="!isInMaintenance"
    :title="$t('forgot.title')"
    :description="description"
    class="forgot"
  >
    <!-- Form -->
    <Form
      v-if="!submitted"
      :links="formLinks"
      :data="formData"
      type="forgot"
      @submitted="handleFormSubmitted"
    />
    <!-- Link -->
    <router-link
      v-else
      :to="formLinks[0].to"
      class="link-alt"
    >
      {{ formLinks[0].label }}
    </router-link>
  </Box>

  <!-- Maintenance -->
  <Maintenance
    v-else
  />
</template>

<script>
import settings from '@/mixins/settings';
import redirect from '@/mixins/redirect';

import Box from '@/components/Container/Box.vue';
import Form from '@/components/Form.vue';
import Maintenance from "@/components/Maintenance.vue";

export default {
  name: 'Forgot',
  components: {
    Maintenance,
    Box,
    Form,
  },
  mixins: [settings, redirect],
  data () {
    return {
      submitted: false,
      email: null,
      formLinks: [
        {
          label: this.$t('forgot.back'),
          to: `/${window.location.search}`,
        },
      ],
      formData: [
        {
          type: 'email',
          name: 'email',
          model: 'email',
          placeholder: this.$t('form.email_example'),
          validations: {
            required: true,
            regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
          },
          validationMessage: 'email',
          maxLength: 250,
        },
      ],
    };
  },
  computed: {
    // Check if the form is submitted & change description
    description () {
      const { email } = this;

      return this.submitted
        ? this.$t('forgot.message', { email })
        : this.$t('forgot.description')
    },
  },
  methods: {
    // Set "submitted" state
    handleFormSubmitted (email) {
      this.email = email;
      this.submitted = true;
    },
  },
};
</script>
