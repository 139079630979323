import { SITES } from '@/config';

export default {
  computed: {
    // Return the Current Site Key
    site () {
      return this.$route.query.site;
    },
    // Return the Redirect Uri
    redirectUri () {
      return this.$route.query.redirect_uri;
    },
    // Return the Token
    token () {
      return this.$route.query.token;
    },
    // Return the Lang
    lang () {
      return this.$route.query.lang || process.env.VUE_APP_I18N_FALLBACK_LOCALE;
    },
    // Return the Prompt Visibility
    showPrompt () {
      const { show_prompt } = this.$route.query;

      return show_prompt && show_prompt === 'true';
    },
    // Conditionnal Site Properties
    isSiteClub1909 () {
      return this.site === 'club1909';
    },
    isSiteGamification () {
      return this.site === '7thplayernhl';
    },
    // On all routes check if the params "site" and "redirect_uri" are valid
    // If route is "reset", check the token instead
    // Do not authorize app if a site does not have a redirect_uri that is from it's config instance
    isAppAuthorized () {
      const currentSiteMapping = SITES[this.site];
      let authorize = false;

      if (this.$route.name === 'reset') {
        authorize = this.token !== undefined;
      } else {
        authorize = currentSiteMapping !== undefined
          && currentSiteMapping.hosts.find(host => this.redirectUri.startsWith(host)) !== undefined;
      }

      return authorize;
    },
    isInMaintenance () {
      return false;
    },
  },
};
