<template>
  <!-- Full -->
  <div class="full">
    <!-- Logo -->
    <img
      src="@/assets/img/logo.svg"
      alt="Club 1909 Logo"
      class="full__logo animate__animated animate__flipInX"
    >
    <!-- Slot (Top) -->
    <slot name="top" />
    <!-- Title -->
    <h2 v-if="title">
      {{ title }}
    </h2>
    <!-- Description -->
    <p
      v-if="description"
      class="full__description"
    >
      {{ description }}
    </p>
    <!-- Slot (Bottom) -->
    <slot name="bottom" />
  </div>
</template>

<script>
export default {
  name: 'Full',
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
// General
.full {
  margin: auto;
  max-width: $container-width;

  h2 {
    margin-bottom: $spacer/2;
  }
}

// Description
.full__description {
  margin-bottom: $spacer*2;
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
}

// Logo
.full__logo {
  display: block;

  @include rem(width, 160px);

  margin: 0 auto $spacer*3;
  animation-delay: 0.2s;
}
</style>
