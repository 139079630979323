<template>
  <!-- Login -->
  <Box
    v-if="showLogin && !isInMaintenance"
    :title="$t('login.title')"
    :description="$t('prompt.must_be_member')"
    :description-mobile="false"
    :disclaimer="$t('prompt.disclaimer', { href: disclaimerLink })"
    class="login"
  >
    <!-- Form -->
    <Form
      :data="formData"
      :links="formLinks"
      type="login"
    />
  </Box>

  <!-- Maintenance -->
  <Maintenance
    v-else
  />
</template>

<script>
import Cookies from 'js-cookie';

import settings from '@/mixins/settings';
import redirect from '@/mixins/redirect';

import Box from '@/components/Container/Box.vue';
import Form from '@/components/Form.vue';
import Maintenance from "@/components/Maintenance.vue";

export default {
  name: 'Login',
  components: {
    Maintenance,
    Box,
    Form,
  },
  mixins: [settings, redirect],
  data () {
    return {
      showLogin: false,
      disclaimerLink: `/forgot${window.location.search}`,
      formLinks: [
        {
          label: this.$t('forgot.cta'),
          to: `/forgot${window.location.search}`,
        },
      ],
      formData: [
        {
          type: 'email',
          name: 'email',
          model: 'email',
          placeholder: this.$t('form.email_example'),
          validations: {
            required: true,
            regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
          },
          validationMessage: 'email',
          maxLength: 250,
        },
        {
          type: 'password',
          name: 'password',
          model: 'password',
          validations: {
            required: true,
          },
          validationMessage: 'mandatory',
          maxLength: 20,
        },
      ],
    };
  },
  created () {
    // If we detect a login cookie, redirect to website with token
    const token = Cookies.get('ch-sso-token');

    if (token) {
      const tokenQuery = `?token=${token}`
      let originAppQuery = '';

      if (this.isSiteClub1909 && this.$route.query.origin === 'app') {
        originAppQuery = token ? '&origin=app' : '?origin=app';
      }

      const parsedUri = `${this.redirectUri}${tokenQuery}${originAppQuery}`;

      this.redirect(parsedUri, 'login');
    } else {
      this.showLogin = true;
    }
  },
};
</script>
