var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{class:[
    'form',
    { 'form--light': _vm.light } ]},[_vm._l((_vm.data),function(field,index){return _c('div',{key:index,staticClass:"form-group"},[(field.type === 'separator')?_c('hr'):_vm._e(),(field.type === 'title')?_c('h4',[_vm._v(" "+_vm._s(_vm.$t(field.label))+" ")]):_vm._e(),(!['separator', 'title', 'checkbox', 'select_region'].includes(field.type)
        && (!field.isManualEntry
          || (field.isManualEntry && _vm.manualEntry)))?_c('label',{staticClass:"form-label",attrs:{"for":field.model},domProps:{"innerHTML":_vm._s(_vm.formatInputLabel(field))}}):_vm._e(),(['select_region'].includes(field.type)
        && _vm.inputFields[field.sibling_model]
        && (_vm.inputFields[field.sibling_model] === 'CA'
          || _vm.inputFields[field.sibling_model] === 'US'))?_c('label',{staticClass:"form-label",attrs:{"for":field.model},domProps:{"innerHTML":_vm._s(_vm.formatInputLabel(field))}}):_vm._e(),((field.type)==='checkbox'&&(['text', 'email'].includes(field.type)
        && (!field.isManualEntry || field.isManualEntry && _vm.manualEntry)))?_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.inputFields[field.model]),expression:"inputFields[field.model]"},{name:"validate",rawName:"v-validate",value:(field.validations),expression:"field.validations"}],class:[
        'form-input',
        { 'is-invalid': _vm.fieldIsInvalid(field.model) || (field.type === 'email' && _vm.emailAlreadyUsed) }
      ],attrs:{"id":field.model,"data-vv-name":field.model,"name":field.model,"data-vv-scope":field.model,"placeholder":field.placeholder,"maxlength":field.maxLength,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputFields[field.model])?_vm._i(_vm.inputFields[field.model],null)>-1:(_vm.inputFields[field.model])},on:{"change":function($event){var $$a=_vm.inputFields[field.model],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.inputFields, field.model, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.inputFields, field.model, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.inputFields, field.model, $$c)}}}},field.type === 'email' ? { blur: _vm.checkEmail } : {})):((field.type)==='radio'&&(['text', 'email'].includes(field.type)
        && (!field.isManualEntry || field.isManualEntry && _vm.manualEntry)))?_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.inputFields[field.model]),expression:"inputFields[field.model]"},{name:"validate",rawName:"v-validate",value:(field.validations),expression:"field.validations"}],class:[
        'form-input',
        { 'is-invalid': _vm.fieldIsInvalid(field.model) || (field.type === 'email' && _vm.emailAlreadyUsed) }
      ],attrs:{"id":field.model,"data-vv-name":field.model,"name":field.model,"data-vv-scope":field.model,"placeholder":field.placeholder,"maxlength":field.maxLength,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputFields[field.model],null)},on:{"change":function($event){return _vm.$set(_vm.inputFields, field.model, null)}}},field.type === 'email' ? { blur: _vm.checkEmail } : {})):(['text', 'email'].includes(field.type)
        && (!field.isManualEntry || field.isManualEntry && _vm.manualEntry))?_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.inputFields[field.model]),expression:"inputFields[field.model]"},{name:"validate",rawName:"v-validate",value:(field.validations),expression:"field.validations"}],class:[
        'form-input',
        { 'is-invalid': _vm.fieldIsInvalid(field.model) || (field.type === 'email' && _vm.emailAlreadyUsed) }
      ],attrs:{"id":field.model,"data-vv-name":field.model,"name":field.model,"data-vv-scope":field.model,"placeholder":field.placeholder,"maxlength":field.maxLength,"type":field.type},domProps:{"value":(_vm.inputFields[field.model])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputFields, field.model, $event.target.value)}}},field.type === 'email' ? { blur: _vm.checkEmail } : {})):_vm._e(),((field.type)==='checkbox'&&(['password'].includes(field.name)))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputFields[field.model]),expression:"inputFields[field.model]"},{name:"validate",rawName:"v-validate",value:(field.validations),expression:"field.validations"}],class:{
        'form-input': true,
        'is-invalid': _vm.fieldIsInvalid(field.model) || (_vm.passwordFeedbackVisible && !_vm.passwordFormatValid),
        'is-success': _vm.passwordFeedbackVisible && _vm.passwordFormatValid,
      },attrs:{"id":field.model,"data-vv-name":field.model,"name":field.model,"data-vv-scope":field.model,"maxlength":field.maxLength,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputFields[field.model])?_vm._i(_vm.inputFields[field.model],null)>-1:(_vm.inputFields[field.model])},on:{"input":_vm.handlePasswordInput,"change":function($event){var $$a=_vm.inputFields[field.model],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.inputFields, field.model, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.inputFields, field.model, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.inputFields, field.model, $$c)}}}}):((field.type)==='radio'&&(['password'].includes(field.name)))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputFields[field.model]),expression:"inputFields[field.model]"},{name:"validate",rawName:"v-validate",value:(field.validations),expression:"field.validations"}],class:{
        'form-input': true,
        'is-invalid': _vm.fieldIsInvalid(field.model) || (_vm.passwordFeedbackVisible && !_vm.passwordFormatValid),
        'is-success': _vm.passwordFeedbackVisible && _vm.passwordFormatValid,
      },attrs:{"id":field.model,"data-vv-name":field.model,"name":field.model,"data-vv-scope":field.model,"maxlength":field.maxLength,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputFields[field.model],null)},on:{"input":_vm.handlePasswordInput,"change":function($event){return _vm.$set(_vm.inputFields, field.model, null)}}}):(['password'].includes(field.name))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputFields[field.model]),expression:"inputFields[field.model]"},{name:"validate",rawName:"v-validate",value:(field.validations),expression:"field.validations"}],class:{
        'form-input': true,
        'is-invalid': _vm.fieldIsInvalid(field.model) || (_vm.passwordFeedbackVisible && !_vm.passwordFormatValid),
        'is-success': _vm.passwordFeedbackVisible && _vm.passwordFormatValid,
      },attrs:{"id":field.model,"data-vv-name":field.model,"name":field.model,"data-vv-scope":field.model,"maxlength":field.maxLength,"type":field.type},domProps:{"value":(_vm.inputFields[field.model])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputFields, field.model, $event.target.value)},_vm.handlePasswordInput]}}):_vm._e(),(field.model === 'password' && _vm.passwordFeedbackVisible)?_c('div',{class:[
        'form-password-feedback',
        { 'is-success': _vm.passwordFormatValid } ]},_vm._l((Object.entries(field.requirements)),function(condition,conditionIndex){return _c('div',{key:("pw-req-" + conditionIndex + "-" + (condition[conditionIndex]))},[(condition[1] === false)?[_c('i',{staticClass:"material-icons is-danger"},[_vm._v("close")]),_c('span',{staticClass:"is-danger"},[_vm._v(" "+_vm._s(_vm.formatFeedback(condition))+" ")])]:[_c('i',{staticClass:"material-icons is-success"},[_vm._v("check")]),_c('span',{staticClass:"is-success"},[_vm._v(" "+_vm._s(_vm.formatFeedback(condition))+" ")])]],2)}),0):_vm._e(),((field.type)==='checkbox'&&(['password_confirmation'].includes(field.name)))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputFields[field.model]),expression:"inputFields[field.model]"},{name:"validate",rawName:"v-validate",value:(field.validations),expression:"field.validations"}],class:{
        'form-input': true,
        'is-invalid': _vm.fieldIsInvalid(field.model),
      },attrs:{"id":field.model,"data-vv-name":field.model,"name":field.model,"data-vv-scope":field.model,"maxlength":field.maxLength,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputFields[field.model])?_vm._i(_vm.inputFields[field.model],null)>-1:(_vm.inputFields[field.model])},on:{"input":_vm.checkPasswordMatch,"change":function($event){var $$a=_vm.inputFields[field.model],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.inputFields, field.model, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.inputFields, field.model, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.inputFields, field.model, $$c)}}}}):((field.type)==='radio'&&(['password_confirmation'].includes(field.name)))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputFields[field.model]),expression:"inputFields[field.model]"},{name:"validate",rawName:"v-validate",value:(field.validations),expression:"field.validations"}],class:{
        'form-input': true,
        'is-invalid': _vm.fieldIsInvalid(field.model),
      },attrs:{"id":field.model,"data-vv-name":field.model,"name":field.model,"data-vv-scope":field.model,"maxlength":field.maxLength,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputFields[field.model],null)},on:{"input":_vm.checkPasswordMatch,"change":function($event){return _vm.$set(_vm.inputFields, field.model, null)}}}):(['password_confirmation'].includes(field.name))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputFields[field.model]),expression:"inputFields[field.model]"},{name:"validate",rawName:"v-validate",value:(field.validations),expression:"field.validations"}],class:{
        'form-input': true,
        'is-invalid': _vm.fieldIsInvalid(field.model),
      },attrs:{"id":field.model,"data-vv-name":field.model,"name":field.model,"data-vv-scope":field.model,"maxlength":field.maxLength,"type":field.type},domProps:{"value":(_vm.inputFields[field.model])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputFields, field.model, $event.target.value)},_vm.checkPasswordMatch]}}):_vm._e(),(['date'].includes(field.type))?_c('date-dropdown',{attrs:{"id":field.model,"i18n":_vm.$t('form.months'),"max-year":_vm.maxYear},model:{value:(_vm.inputFields[field.model]),callback:function ($$v) {_vm.$set(_vm.inputFields, field.model, $$v)},expression:"inputFields[field.model]"}}):_vm._e(),(['date'].includes(field.type))?_c('span',{staticClass:"form-date-disclaimer",domProps:{"innerHTML":_vm._s(_vm.$t('form.date_disclaimer'))}}):_vm._e(),(['select'].includes(field.type))?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputFields[field.model]),expression:"inputFields[field.model]"},{name:"validate",rawName:"v-validate",value:(field.validations),expression:"field.validations"}],class:{
        'form-input': true,
        'form-select': true,
        'is-invalid': _vm.fieldIsInvalid(field.model)
      },attrs:{"id":field.model,"data-vv-name":field.model,"placeholder":"test"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.inputFields, field.model, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","selected":"","disabled":""},domProps:{"innerHTML":_vm._s(_vm.$t(("form.select_" + (field.name))))}}),_vm._l((field.options),function(option,i){return _c('option',{key:i,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])})],2):_vm._e(),(['autocomplete'].includes(field.type))?[_c('vue-google-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:({ required: !_vm.manualEntry }),expression:"{ required: !manualEntry }"}],staticClass:"form-input",class:{
          'is-invalid': _vm.fieldIsInvalid(field.model)
        },attrs:{"id":field.model,"data-vv-name":field.model,"placeholder":_vm.$t('form.start_typing'),"enable-geolocation":true,"type":"search"},on:{"placechanged":_vm.fillAddressData},model:{value:(_vm.inputFields.autocompleteAddress),callback:function ($$v) {_vm.$set(_vm.inputFields, "autocompleteAddress", $$v)},expression:"inputFields.autocompleteAddress"}}),(!_vm.manualEntry)?_c('a',{staticClass:"form-manual-entry",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleToggleAddress.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('form.enter_address'))+" ")]):_vm._e()]:_vm._e(),(['checkbox'].includes(field.type))?_c('Checkbox',{directives:[{name:"validate",rawName:"v-validate",value:(field.validations),expression:"field.validations"}],attrs:{"id":field.model,"data-vv-name":field.model,"label":field.label,"value":_vm.inputFields[field.model]},model:{value:(_vm.inputFields[field.model]),callback:function ($$v) {_vm.$set(_vm.inputFields, field.model, $$v)},expression:"inputFields[field.model]"}}):_vm._e(),(['select_country'].includes(field.type)
        && (field.isManualEntry && _vm.manualEntry))?_c('country-select',{directives:[{name:"validate",rawName:"v-validate",value:(field.validations),expression:"field.validations"}],class:{
        'is-invalid': _vm.fieldIsInvalid(field.model)
      },attrs:{"id":field.model,"country":_vm.inputFields[field.model],"data-vv-name":field.model,"name":field.model,"data-vv-scope":field.model,"placeholder":_vm.$t('form.select_country'),"usei18n":false,"top-country":"CA","class-name":"form-input form-select"},model:{value:(_vm.inputFields[field.model]),callback:function ($$v) {_vm.$set(_vm.inputFields, field.model, $$v)},expression:"inputFields[field.model]"}}):_vm._e(),(['select_region'].includes(field.type)
        && (_vm.inputFields[field.sibling_model]
          && _vm.inputFields[field.sibling_model] === 'CA'
          || _vm.inputFields[field.sibling_model] === 'US')
        && (field.isManualEntry && _vm.manualEntry))?_c('region-select',{directives:[{name:"validate",rawName:"v-validate",value:(field.validations),expression:"field.validations"}],class:{
        'is-invalid': _vm.fieldIsInvalid(field.model)
      },attrs:{"id":field.model,"region":_vm.inputFields[field.model],"country":_vm.inputFields[field.sibling_model],"data-vv-name":field.model,"name":field.model,"data-vv-scope":field.model,"placeholder":_vm.$t('form.select_region'),"usei18n":false,"class-name":"form-input form-select"},model:{value:(_vm.inputFields[field.model]),callback:function ($$v) {_vm.$set(_vm.inputFields, field.model, $$v)},expression:"inputFields[field.model]"}}):_vm._e(),(_vm.fieldIsInvalid(field.model))?_c('Alert',{attrs:{"message":_vm.formatValidationMessage(field),"type":['autocomplete'].includes(field.type) ? 'overflow' : null,"light":_vm.isCreate}}):_vm._e()],2)}),(_vm.isCreate)?_c('div',{staticClass:"form-group"},[(_vm.isCreate)?_c('Checkbox',{attrs:{"id":"terms"},model:{value:(_vm.termsChecked),callback:function ($$v) {_vm.termsChecked=$$v},expression:"termsChecked"}},[(_vm.isCreate)?_c('p',{staticClass:"form-terms"},[_vm._v(" "+_vm._s(_vm.$t('form.terms_desc1'))+" "),_c('a',{attrs:{"href":_vm.$t('form.terms_url'),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('form.terms_desc2'))+" ")]),(_vm.isSiteGamification)?[_vm._v(" "+_vm._s(_vm.$t('form.terms_desc3'))+" "),_c('a',{attrs:{"href":_vm.$t('chgamification.rules'),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('form.terms_desc4'))+" ")])]:_vm._e()],2):_vm._e()]):_vm._e()],1):_vm._e(),(_vm.isCreate)?_c('div',{staticClass:"form-group"},[(_vm.isCreate)?_c('p',{staticClass:"optin"},[_vm._v(" "+_vm._s(_vm.$t('form.optin_desc1'))+" "),_c('a',{attrs:{"href":_vm.$t('form.optin_privacy_policies_url'),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('form.optin_desc2'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('form.optin_desc3'))+" "),_c('a',{attrs:{"href":_vm.$t('form.optin_terms_conditions_url'),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('form.optin_desc4'))+" ")])]):_vm._e()]):_vm._e(),_c('div',{class:[
      'form-submit',
      { 'form-submit--multiple-links': _vm.links && _vm.links.length > 1 } ]},[_c('invisible-recaptcha',{staticClass:"btn",class:{
        'is-loading': _vm.isLoading,
      },attrs:{"sitekey":_vm.recaptchaSiteKey,"callback":_vm.handleSubmit,"type":"submit"}},[(_vm.isLoading)?[_c('span',{staticClass:"loading-spin-container"},[_c('i',{staticClass:"material-icons loading-spin"},[_vm._v("replay")])])]:_vm._e(),_c('span',{staticClass:"btn__label"},[_vm._v(_vm._s(_vm.btnLabel))])],2),(_vm.links)?_vm._l((_vm.links),function(link){return _c('router-link',{key:link.label,staticClass:"link-alt",attrs:{"to":link.to}},[_vm._v(" "+_vm._s(link.label)+" ")])}):_vm._e()],2),(_vm.formMessage)?_c('Alert',{attrs:{"message":_vm.formMessage,"light":_vm.isCreate,"type":"single"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }