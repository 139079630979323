<template>
  <!-- Date dropdown container -->
  <div class="date-dropdown">
    <!-- Day select -->
    <select
      :id="id"
      v-model="selectedDay"
      class="date-dropdown__select"
      name="days"
    >
      <option
        v-for="day in getDaysInMonth(selectedMonth)"
        :key="day"
        :value="day"
        v-html="day"
      />
    </select>
    <!-- Month select -->
    <select
      v-model="selectedMonth"
      class="date-dropdown__select"
      name="months"
      @change="handleMonthUpdate"
    >
      <option
        v-for="month in months"
        :key="month"
        :value="month"
        v-html="getMonthStringValue(month)"
      />
    </select>
    <!-- Year select -->
    <select
      v-model="selectedYear"
      class="date-dropdown__select"
      name="years"
    >
      <option
        v-for="year in years"
        :key="year"
        :value="year"
        v-html="year"
      />
    </select>
  </div>
</template>

<script>
export default {
  name: 'DateDropdown',
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    i18n: {
      type: String,
      required: false,
      default: null,
    },
    minYear: {
      type: Number,
      required: false,
      default: new Date().getFullYear() - 100,
    },
    maxYear: {
      type: Number,
      required: false,
      default: new Date().getFullYear(),
    },
    defaultYear: {
      type: Number,
      required: false,
      default: 1990,
    },
    defaultMonth: {
      type: Number,
      required: false,
      default: 1,
    },
    defaultDay: {
      type: Number,
      required: false,
      default: 1,
    }
  },
  data () {
    return {
      selectedYear: this.defaultYear,
      selectedMonth: this.defaultMonth,
      selectedDay: this.defaultDay,
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    };
  },
  computed: {
    /**
     * Get an array that contains a range of dates
     *
     * @returns {Array}
     */
    years () {
      const years = [];
      const { minYear, maxYear } = this;
      for (let i = minYear; i <= maxYear; i++) {
        years.push(i);
      }
      return years;
    },
    /**
     * Get a string that follows those specifications: yyyy-mm-dd
     * Later / To reassess: Permit a more vast array of possible outputs (array, dots instead of dashes, etc)
     *
     * @returns {String}
     */
    formattedDate () {
      const paddedMonth = this.padValue(this.selectedMonth);
      const paddedDay = this.padValue(this.selectedDay);
      return `${this.selectedYear}-${paddedMonth}-${paddedDay}`;
    },
  },
  watch: {
    formattedDate () {
      this.emitInput();
    },
  },
  created () {
    this.emitInput();
  },
  methods: {
    /**
     * Get the text of a current month value. If the i18n prop
     * is passed to the component, it's text has the proprity
     * i18n string prop format: 'month_name1, month_name2, month_name3, etc',
     *
     * @param {Number} month - The current month's number
     * @returns {String}
     */
    getMonthStringValue (currentMonth) {
      let monthsNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      // If the component has a i18n string prop, replace the default monthsNames value by it
      if (this.i18n !== null) {
        // i18n treatment: Remove blank spaces between months names,
        // split by comma, create array and reassign default value
        monthsNames = this.i18n.replace(/\s/g, '').split(',');
      }
      return monthsNames[currentMonth - 1];
    },
    /**
     * Get the maximum number of days a month can contain
     *
     * @param {Number} selectedMonth - The current month's number
     * @returns {Number}
     */
    getDaysInMonth (selectedMonth) {
      switch (selectedMonth) {
        // February
        case 2:
          return 29;
        // April, June, September, November
        case 4:
        case 6:
        case 9:
        case 11:
          return 30;
        // January, March, May, July, August, October, December
        default:
          return 31;
      }
    },
    /**
     * Every time a month is updated, we check if the day value is over the
     * permitted maximum value. If this is the case, we set the day value
     * at the maximum permitted level
     *
     * @returns {Void}
     */
    handleMonthUpdate () {
      const maxValue = this.getDaysInMonth(this.selectedMonth);
      if (this.selectedDay > maxValue) {
        this.selectedDay = maxValue;
      }
    },
    /**
     * Add a leading zero (0) if a value is < 10
     *
     * @returns {String}
     */
    padValue (value) {
      return String(value).padStart(2, '0');
    },
    /**
     * Emit the current formatted value to the parent
     * for treatment purposes
     *
     * @returns {Void}
     */
    emitInput () {
      this.$emit('input', this.formattedDate);
    },
  }
}
</script>

<style lang="scss" scoped>
// General
.date-dropdown {
  display: flex;

  @include rem(max-width, 400px);
}

// Inputs
.date-dropdown__select {
  margin-right: $spacer/2;

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: $bp-xs) {
    margin-right: $spacer/1.5;
  }
}
</style>

