import Vue from 'vue';
import Router from 'vue-router';
import login from './pages/login.vue';
import logout from './pages/logout.vue';
import create from './pages/create.vue';
import forgot from './pages/forgot.vue';
import reset from './pages/reset.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: login,
    },
    {
      path: '/logout',
      name: 'logout',
      component: logout,
    },
    {
      path: '/create',
      name: 'create',
      component: create,
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: forgot,
    },
    {
      path: '/reset',
      name: 'reset',
      component: reset,
    },
  ],
  scrollBehavior () {
    return { x: 0, y: 0 };
  },
});
