<template>
  <Box
    :title="$t('maintenance.title')"
    :description="$t('maintenance.description')"
    :description-mobile="false"
    class="login"
  >
    <a
      v-if="!full"
      href="#"
      class="btn"
      @click.prevent="getLink"
    >
      <span class="btn__label">
        {{ $t('maintenance.cta') }}
      </span>
    </a>
  </Box>
</template>

<script>

import Box from "@/components/Container/Box.vue";
import settings from "@/mixins/settings";
import redirect from "@/mixins/redirect";
import { DOMAIN } from '@/config';

export default {
  name: "Maintenance",
  components: {
    Box,
  },
  mixins: [settings, redirect],
  props: {
    full: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    getLink () {
      const env = process.env.VUE_APP_ENV;
      this.redirect(DOMAIN[this.site][env]);
    },
  },

};
</script>
