<template>
  <div />
</template>

<script>
import Cookies from 'js-cookie';

import settings from '@/mixins/settings';
import redirect from '@/mixins/redirect';

export default {
  name: 'Logout',
  components: {
  },
  mixins: [settings, redirect],
  data () {
    return {};
  },
  created () {
    Cookies.remove('ch-sso-token', { domain: '.canadiens.com' });
    this.redirect(this.redirectUri, 'logout');
  },
};
</script>
