<template>
  <!-- Box -->
  <div class="box">
    <!-- Logo -->
    <img
      src="@/assets/img/logo.svg"
      alt="Club 1909 Logo"
      class="box__logo animate__animated animate__flipInX"
    >
    <!-- Content -->
    <div class="box__content">
      <!-- Title -->
      <h2 v-if="title">
        {{ title }}
      </h2>
      <!-- Description -->
      <p
        v-if="description"
        :class="{ 'hide-mobile': !descriptionMobile }"
      >
        {{ description }}
      </p>
      <!-- Disclaimer (Hidden for now, not needed anymore) -->
      <!-- <p
        v-if="disclaimer"
        class="box__disclaimer"
        v-html="disclaimer"
      /> -->
      <!-- Slot -->
      <slot />
    </div>
  </div>
</template>

<script>

import settings from "@/mixins/settings";
export default {
  name: 'Box',
  mixins: [settings],
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    descriptionMobile: {
      type: Boolean,
      required: false,
      default: true,
    },
    disclaimer: {
      type: String,
      required: false,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
// General
.box {
  position: relative;
  width: 100%;

  @include rem(max-width, 1000px);

  background-color: $color-primary;
  padding: $spacer*6 $spacer*2;
  margin: $spacer*3 auto $spacer;
  transition: $transition-base;

  h2,
  p,
  /deep/ label {
    color: white;
  }

  p {
    margin-bottom: $spacer*2;
  }

  @media (min-width: $bp-md) {
    padding: $spacer*8 $spacer*2;
  }
}

// Logo
.box__logo {
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;

  @include rem(width, 160px);

  margin: auto;
  animation-delay: 0.2s;
}

// Content
.box__content {
  max-width: $container-width;
  margin: auto;
}

// Button
/deep/ .btn::before {
  background-color: $color-primary;
}

// Disclaimer
.box__disclaimer {
  padding: $spacer;
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
  border: 2px solid $color-danger;
  border-radius: $border-radius-base;
  color: white;

  /deep/ a {
    color: white;
  }
}
</style>
