<template>
  <!-- Reset -->
  <Box
    v-if="!isInMaintenance"
    :title="title"
    :description="description"
    class="reset"
  >
    <!-- Form -->
    <Form
      v-if="!submitted"
      :data="formData"
      type="reset"
      @submitted="handleFormSubmitted"
    />
    <!-- Link (Go to Club 1909) -->
    <a
      v-else
      :href="$t('reset.link_club1909')"
      class="link-alt"
    >
      {{ $t('reset.go_to_club1909') }}
    </a>
  </Box>

  <!-- Maintenance -->
  <Maintenance
    v-else
  />
</template>

<script>
import settings from '@/mixins/settings';
import redirect from '@/mixins/redirect';

import Box from '@/components/Container/Box.vue';
import Form from '@/components/Form.vue';
import Maintenance from "@/components/Maintenance.vue";

export default {
  name: 'Reset',
  components: {
    Maintenance,
    Box,
    Form,
  },
  mixins: [settings, redirect],
  data () {
    return {
      submitted: false,
      formData: [
        {
          type: 'password',
          name: 'password',
          model: 'password',
          validations: {
            required: true,
          },
          requirements: {
            password_has_accepted_char: false,
            password_has_length: false,
            password_has_number: false,
            password_has_capital: false,
            password_has_lowercase: false,
          },
          validationMessage: 'mandatory',
          maxLength: 20,
        },
        {
          type: 'password',
          name: 'password_confirmation',
          model: 'passwordConfirmation',
          validations: {
            required: true,
          },
          validationMessage: 'password_confirmation',
          maxLength: 20,
        },
      ],
    };
  },
  computed: {
    // Check if the form is submitted & change title
    title () {
      return this.submitted
        ? this.$t('reset.message_title')
        : this.$t('reset.title');
    },
    // Check if the form is submitted & change description
    description () {
      return this.submitted
        ? this.$t('reset.message_description')
        : this.$t('reset.description');
    },
  },
  methods: {
    // Set "submitted" state
    handleFormSubmitted () {
      this.submitted = true;
    },
  },
};
</script>
