export default {
  computed: {
    // Return weither the submit should be executed as a window.location or a postMessage
    isPostMessage () {
      return this.isSiteGamification || this.isSiteClub1909;
    },
  },
  methods: {
    // Redirect as postMessage or as parent location of iframe
    redirect (uri, type) {
      if (this.isPostMessage && type === 'create') {
        window.parent.postMessage(`SSO_APP_REQUEST:${uri}`, '*');
      } else {
        window.parent.location = uri;
      }
    },
  },
};
